import { useEffect, useMemo, useState } from 'react';
import { getCookies } from '@hol-jsp/dashboard-dsl';
import { useRouter } from 'next/router';
import { LoadingLayout } from '@/components/layout/Layout';
import { getUserEntryPoint } from '@/helper/utils';

export const Home = () => {
  const [loading, setLoading] = useState(true);
  const router = useRouter();
  const slug = getCookies('slug');
  const isLoggedIn = getCookies('token');
  const { push, asPath } = router;

  const allowedPaths = useMemo(
    () => [
      '/forgot-password',
      '/accept-invitation',
      '/graphql',
      '/invalid-invitation',
      '/invalid-reset-password',
      '/page-403',
      '/page-404',
      '/reset-password',
      '/renew-password',
      '/sign-up',
      '/login',
    ],
    []
  );

  const authPaths = useMemo(
    () => [
      `/${slug}/activity`,
      `/${slug}/bookmark`,
      `/${slug}/company-management`,
      `/${slug}/compliance-attachment`,
      `/${slug}/compliance-monitor`,
      `/${slug}/home`,
      `/${slug}/notification`,
      `/${slug}/obligation-updates`,
      `/${slug}/personal-setting`,
      `/${slug}/rcs-setting`,
      `/${slug}/regulation`,
      `/${slug}/search`,
      `/${slug}/sector`,
      `/${slug}/updating-content`,
    ],
    [slug]
  );

  const getStaticPath = (path: string) => {
    const parts = path.split('/');
    return `/${parts[1]}/${parts[2]}`;
  };

  useEffect(() => {
    const handleRedirect = async () => {
      if (!isLoggedIn) {
        if (!allowedPaths.includes(asPath.split('?')[0])) {
          await push('/login');
        } else {
          await push(asPath);
        }
      } else if (isLoggedIn) {
        if (['/', '', `/${slug}`, `/${slug}/`].includes(asPath)) {
          await push(`/${slug}/home`);
        } else {
          const staticPath = getStaticPath(asPath.split('?')[0]);
          const rootPath = staticPath.split('/')[1];
          if (allowedPaths.includes(`/${rootPath}`)) {
            const entryPoint = getUserEntryPoint(true);
            await push(`/${slug}/${entryPoint}`);
          } else {
            if (!authPaths.includes(staticPath)) {
              await push('/page-404');
            } else {
              await push(asPath);
            }
          }
        }
      }
      setLoading(false);
    };

    handleRedirect();
  }, [isLoggedIn, slug, push, asPath, allowedPaths, authPaths]);

  if (loading) {
    return <LoadingLayout />;
  }

  return null;
};

export default Home;
